@mixin UniCreditCYFont($base: '~coredde/lib/assets/fonts/') {
  @font-face {
    font-family: 'UniCreditCY';
    src: url('#{$base}unicredit_cy-bold.woff2') format('woff2');
    font-weight: bold;
  }
  @font-face {
    font-family: 'UniCreditCY';
    src: url('#{$base}unicredit_cy-regular.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: 'UniCreditCY';
    src: url('#{$base}unicredit_cy-light.woff2') format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: 'UniCreditCY';
    src: url('#{$base}unicredit_cy-medium.woff2') format('woff2');
    font-weight: 500;
  }
}
