@import '~antd/dist/antd.css';
@import '~@g4p/react-styleguide-library/dist/react-styleguide-library.esm';
@import '~@g4p/react-styleguide-library/public/fonts/Unicredit/unicredit-font';
@import '~coredde/lib/assets/fonts/unicredit-cy-font';
@include UniCreditFont();
@include UniCreditCYFont();

@import '~@g4p/react-styleguide-library/public/fonts/Asterisk/asterisk';

@include AsteriskFont();

:root {
  --uds-font-family: "UniCredit", "UniCreditCY", sans-serif;
}

*, *:after, *:before {
  font-family: var(--uds-font-family), "UniCredit", "UniCreditCY", sans-serif
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes d02fa {
  100% {
    transform: rotate(1turn);
  }
}

// workarounds until fixes are present in G4P
.uds__modal__logo {
  display: none !important;
}
.ant-empty-img-simple-g {
  stroke: #c4c4c4;
}
.ant-empty-description {
  color: #c4c4c4;
}
.ant-input-suffix > *:not(:last-child) {
  margin-top: 2px;
}

.uds__menu-portal .signEntry {
  color: #f7ba08;
}
