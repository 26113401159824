html, body, div[id^=single-spa-application] {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0
}

html {
  min-height: 100%
}

body {
  min-height: 100%;
  min-width: 100%;
  padding: 0
}

html, body {
  margin: 0;
  color: #262626
}

* {
  font-family: "UniCredit", "UniCreditCY", sans-serif;
}

#webpack-dev-server-client-overlay {
  display: none !important
}

.modalConfirm .ant-modal-body {
  padding: 0 0;
  margin: 0;
  max-height: none
}

.modalConfirm .ant-modal-confirm-title {
  font-size: 35px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 38.5px;
  letter-spacing: normal;
  color: var(--color-black);
  padding: 30px 64px 0 40px
}

.modalConfirm .ant-modal-confirm-content {
  padding: 10px 40px;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  color: var(--color-black);
  max-height: 40vh;
  overflow: auto
}

.modalConfirm .ant-modal-confirm-btns {
  padding: 0 40px;
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between
}

.modalConfirm .ant-modal-close-x {
  font-size: 30px;
  color: var(--color-black)
}

.modalConfirm.uds__modal .ant-modal-confirm-content::-webkit-scrollbar {
  width: 8px
}

.modalConfirm.uds__modal .ant-modal-confirm-content::-webkit-scrollbar-thumb {
  background: var(--color-scrollback-thumb);
  border-radius: 4px
}

.modalConfirm.uds__modal .ant-modal-confirm-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--color-secondary);
  background-color: var(--color-background);
  border-radius: 4px
}

@media screen and (max-width: 767px) {
  .modalConfirm .ant-modal-confirm-title {
    padding-left: 20px;
    padding-right: 90px
  }

  .modalConfirm .ant-modal-confirm-content {
    padding-left: 25px;
    padding-right: 25px
  }

  .modalConfirm .ant-modal-confirm-btns {
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column
  }

  .modalConfirm .ant-modal-confirm-btns .ant-btn {
    margin-bottom: 10px;
    width: 100%
  }

  .modalConfirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-left: 0
  }
}

button.ant-btn:focus:focus:not(:focus-visible) {
  outline: none;
  color: var(--color-accent)
}

button.ant-btn:focus-visible {
  outline: var(--color-accent) auto 1px;
  color: var(--color-accent-hover)
}

button.ant-btn:focus-visible svg {
  fill: var(--color-accent-hover)
}

button.ant-btn.ant-btn-primary:focus:not(:focus-visible) {
  color: var(--color-white)
}

button.ant-btn.ant-btn-primary:focus-visible {
  outline: var(--color-accent) auto 1px;
  color: var(--color-white);
  background-color: var(--color-accent-hover)
}

button.ant-btn.ant-btn-primary:focus-visible svg {
  fill: var(--color-white)
}

button.ant-btn.ant-btn-link {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity .3s ease
}

button.ant-btn.ant-btn-link svg {
  width: 16px;
  height: 16px;
  fill: var(--color-accent)
}

button.ant-btn.ant-btn-link:hover {
  color: var(--color-accent);
  opacity: .8
}

button.ant-btn-loading span {
  display: contents
}

.dropdown-custom-overlay {
  max-height: 220px;
  overflow-y: auto;
  border: 1px solid var(--color-input-border)
}

.dropdown-custom-overlay:before {
  top: 0;
  bottom: 0
}

.dropdown-custom-overlay::-webkit-scrollbar {
  width: 3px
}

.dropdown-custom-overlay::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f0f2f5
}

.dropdown-custom-overlay::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #666
}

.table-action-menu {
  max-width: 240px;
  min-width: 200px !important;
  border-radius: 4px;
  background-color: var(--color-black);
  z-index: 11
}

.table-action-menu .ant-dropdown-menu {
  padding: 0;
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden
}

.table-action-menu .ant-dropdown-menu-item {
  height: auto;
  padding: 0
}

.custom-dropdown-filter-group .ant-dropdown-menu {
  padding: 0
}

.custom-dropdown-filter-group .ant-dropdown-menu-item {
  height: auto;
  padding: 0
}

.custom-dropdown-filter-group .ant-dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0)
}

.custom-dropdown-filter-group:not(.ant-modal-root .custom-dropdown-filter-group) {
  top: 53px !important
}

.custom-dropdown-currency {
  min-width: initial !important;
  border-top: 0
}

.custom-dropdown-currency .ant-dropdown-menu {
  padding: 0;
  margin-right: -2px;
  border: 1px solid var(--color-input-border);
  border-top: 0;
  box-shadow: none
}

.custom-dropdown-currency .ant-dropdown-menu-item {
  height: auto;
  padding: 0
}

.custom-dropdown-currency .ant-dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0)
}

.uds__select .uds__control, .uds__select-multi .uds__control {
  border-color: #ccc
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #262626;
  font-size: 18px;
  line-height: 24px;
  font-family: "UniCredit", "UniCreditCY", sans-serif;
  content: "*";
  order: 1
}

.ant-form-item-label {
  padding-bottom: 2px !important
}

.ant-form-item-label label {
  display: flex;
  font-family: "UniCredit", "UniCreditCY", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-black);
  padding-bottom: 2px
}

.ant-form-item-label > label .ant-form-item-tooltip {
  order: 2;
  flex-shrink: 0
}

.uds__block-ui__message__container-info {
  background-color: var(--color-accent)
}

.ant-modal .ant-modal-title {
  padding-right: 90px
}

.ant-modal .ant-modal-close-x {
  right: 50px
}

.ant-modal .ant-modal-body {
  margin-bottom: 20px
}

.ant-modal .ant-modal-footer {
  padding-bottom: 20px
}

.ant-modal .ant-modal-close:focus svg {
  outline: none
}

.ant-modal .ant-modal-close:focus-visible svg {
  outline: var(--color-accent) auto 1px
}

@media (min-width: 1400px) {
  .ant-modal {
    min-width: 900px
  }
}

.ant-modal-wrap:has(>.full-height-modal) {
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto
}

.ant-modal-wrap:has(>.full-height-modal) .ant-modal-body {
  max-height: none
}

.ant-modal-wrap:has(>.full-height-modal) .ant-modal-content {
  margin: 20px auto
}

.all-filters-modal .ant-modal-title {
  padding-top: 20px
}

@media (max-width: 768px) {
  .all-filters-modal .ant-modal-title {
    padding-right: 84px
  }
}

.all-filters-modal .ant-modal-body {
  max-height: 70vh
}

.table-custom-placeholder tr.ant-table-placeholder {
  display: none !important
}

.ant-pagination-options .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  text-decoration: none
}

.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort, .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fafafa;
  border-color: var(--color-disabled)
}

.ant-table-column-title .uds__title__column-selected {
  color: initial
}

.ant-table-column-title .uds__title__column {
  user-select: none
}

.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: var(--color-accent)
}

.ant-table-cell.ant-table-column-sort .ant-table-column-title {
  color: var(--color-accent);
  padding-right: 3px
}

.ant-table-column-sorters {
  width: 100%
}

ul.ant-table-pagination li {
  order: 2
}

ul.ant-table-pagination li a {
  display: inline-block;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  padding-left: 2px;
  padding-right: 2px;
  text-decoration: none
}

ul.ant-table-pagination li.ant-pagination-item-active a {
  border-bottom: 3px solid var(--color-accent)
}

ul.ant-table-pagination li .ant-select-dropdown .ant-select-item.ant-select-item-option:hover {
  background-color: var(--color-white-two);
  color: #262626
}

ul.ant-table-pagination li .ant-select-dropdown .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  color: #fff;
  background-color: var(--color-accent)
}

ul.ant-table-pagination li .ant-select {
  border: 1px solid #262626;
  box-shadow: none !important
}

ul.ant-table-pagination li .ant-select .ant-select-item {
  color: #262626
}

ul.ant-table-pagination li .ant-select .ant-select-selection-item {
  text-decoration: none
}

ul.ant-table-pagination li .ant-select .ant-select-selector::after {
  display: none
}

ul.ant-table-pagination li.ant-pagination-options {
  position: relative;
  margin-right: 20px;
  margin-left: 0;
  left: auto;
  order: 1
}

ul.ant-table-pagination li.ant-pagination-total-text {
  display: flex;
  flex: 1;
  order: 0;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase
}

.uds__toast__container .Toastify__toast-container {
  top: 0;
  right: 0;
  padding: 0;
  width: 420px
}

.uds__toast__container .Toastify__toast {
  top: 110px;
  right: 80px;
  width: 420px;
  padding: 15px 20px
}

.uds__toast__container .Toastify__close-button {
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px
}

.uds__toast__container .Toastify__close-button > svg {
  width: 30px;
  height: 30px
}

.uds__toast__container .uds__message__wrapper {
  padding-right: 34px
}

.uds__toast__container .uds__message__wrapper h3 {
  line-height: 22px;
  margin: 5px 0
}

.uds__toast__container .uds__message__wrapper p {
  line-height: 24px;
  margin: 5px 0
}

h1 {
  color: var(--color-black)
}

h1.ant-typography {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 400
}

h2 {
  color: var(--color-black)
}

h2.ant-typography {
  font-size: 35px;
  line-height: 1.1;
  font-weight: 700
}

h3 {
  color: var(--color-black)
}

h3.ant-typography {
  font-size: 30px;
  line-height: 1.1;
  font-weight: 700
}

h4 {
  color: var(--color-black)
}

h4.ant-typography {
  font-size: 20px;
  line-height: 1.1;
  font-weight: 700
}

h5 {
  color: var(--color-black)
}

h5.ant-typography {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700
}

div.ant-typography, p.ant-typography, span.ant-typography, a.ant-typography {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-black)
}

div.ant-upload-drag-container span.ant-typography strong {
  font-size: 14px;
}

div.ant-typography.error, p.ant-typography.error, span.ant-typography.error, a.ant-typography.error {
  color: var(--color-error)
}

a.ant-typography {
  color: var(--color-accent)
}

a.ant-typography:hover {
  color: var(--color-accent-hover)
}

span.ant-typography.label {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 700
}

span.ant-typography.medium {
  font-size: 16px;
  line-height: 1.1
}

span.ant-typography.medium-bold {
  font-weight: 700
}

span.ant-typography.small {
  font-size: 14px;
  line-height: 1.1
}

.uds__control.uds__control--is-disabled {
  border-color: #d9d9d9
}

.uds__menu-list .uds__option.uds__option--is-focused {
  background-color: var(--color-white-two);
  color: var(--color-black)
}

.uds__menu-list .uds__option.uds__option--is-selected {
  background-color: var(--color-accent);
  color: var(--color-white)
}

.uds__select .uds__menu-list, .uds__select-multi .uds__menu-list {
  gap: 0
}

.ant-form-item-has-error .basic-select__control {
  border: 2px solid var(--color-error) !important
}

.basic-select__placeholder, .uds__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.uds__autocomplete-container .ant-select.ant-select-auto-complete.ant-select-status-error {
  border: 2px solid var(--color-error)
}

.uds__autocomplete-container .ant-select.ant-select-auto-complete .ant-select-selector {
  width: 100%
}

.uds__autocomplete-container .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  display: none
}

.ant-select-dropdown {
  box-shadow: none
}

.ant-select-dropdown .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background-color: var(--color-white-two)
}

.ant-select-dropdown .ant-select-item.ant-select-item-option:not(.ant-select-item-option-disabled) {
  transition: none
}

.ant-select-dropdown .ant-select-item.ant-select-item-option:not(.ant-select-item-option-disabled):hover {
  background-color: var(--color-white-two);
  color: #262626
}

.ant-select-clear {
  opacity: 1
}

.imo-popup {
  height: 70% !important
}

.imo-popup .imo-header div {
  display: none !important
}

.imo-popup .imo-add-new {
  display: none
}

.imo-popup .imo-add-new:last-child {
  display: block
}

.imo-popup .imo-add-new button {
  border-color: var(--color-error);
  background-color: var(--color-error);
  color: var(--color-white);
  padding: 10px 5px;
  border-radius: 5px;
  border: 0;
  text-align: center;
  height: 43px !important
}

.imo-popup .imo-overrides-table {
  width: 100%
}

.imo-popup .imo-header .imo-unstyled {
  right: 28px;
  position: absolute;
  font-size: 26px
}

.imo-popup .imo-list-search {
  height: 40px !important;
  border-color: var(--color-input-border) !important;
  color: var(--color-black) !important;
  border-radius: 0 !important;
  line-height: 1 !important;
  padding: 4px 10px !important
}

.imo-module-dialog {
  top: 20% !important;
  min-width: 650px;
  max-width: 900px !important;
  position: fixed;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 !important;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1215686275), 0 6px 16px rgba(0, 0, 0, .0784313725), 0 9px 28px 8px rgba(0, 0, 0, .0509803922);
  pointer-events: auto;
  min-height: 350px;
  display: flex;
  padding: 40px !important
}

.imo-module-dialog form {
  width: 100%
}

.imo-module-dialog input {
  height: 40px !important;
  color: var(--color-black) !important;
  border-radius: 0 !important;
  line-height: 1 !important;
  padding: 4px 10px !important;
  border: 1px solid var(--color-input-border) !important;
  min-width: 400px
}

.imo-module-dialog td {
  float: left;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500
}

.imo-module-dialog table td:first-child {
  width: 150px;
  text-align: left !important;
  font-weight: 500
}

.imo-module-dialog td:last-child {
  width: 100%;
  text-align: left !important
}

.imo-module-dialog tr {
  text-align: center !important;
  padding: 10px 10px 10px 0;
  display: flex
}

.imo-module-dialog h3 {
  font-size: 25px;
  font-weight: 700
}

.imo-module-dialog tbody {
  display: flex;
  flex-direction: column
}

.imo-module-dialog .imo-clear-input {
  font-size: 25px;
  height: 40px !important
}

.imo-module-dialog .imo-dialog-actions {
  position: relative;
  top: 40px;
  margin-bottom: 20px;
  height: 60px
}

.imo-module-dialog .imo-dialog-actions button[type=button] {
  margin-right: 16px;
  background-color: rgba(0, 0, 0, 0);
  color: var(--color-black);
  border: 1px solid var(--color-black);
  line-height: 20px;
  float: left;
  min-height: 50px;
  min-width: 120px;
  cursor: pointer
}

.imo-module-dialog .imo-dialog-actions button[type=submit] {
  border-color: var(--color-error);
  background-color: var(--color-error);
  color: var(--color-white);
  padding: 10px 12px;
  border-radius: 5px;
  border: 0;
  text-align: center;
  min-height: 50px;
  min-width: 120px;
  line-height: 20px;
  float: right;
  cursor: pointer
}

.imo-module-dialog .imo-dialog-actions button[type=submit].imo-overridden {
  background-color: var(--color-accent);
  border-color: var(--color-accent)
}

.custom-accordion .ant-collapse-item {
  border-color: var(--color-accordion-border);
  transition: background-color .2s ease
}

.custom-accordion .ant-collapse-item .ant-collapse-header {
  background-color: rgba(0, 0, 0, 0)
}

.custom-accordion .ant-collapse-item:hover {
  border-color: var(--color-accordion-border);
  background-color: var(--color-white-two)
}

.custom-accordion .ant-collapse-item.ant-collapse-item-active:hover {
  background-color: var(--color-white)
}

.ant-collapse {
  padding-top: 2px
}

.ant-collapse .ant-collapse-item {
  padding: 6px
}

.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  right: 10px
}

.ant-collapse .ant-collapse-item.ant-collapse-item-active {
  padding-bottom: 5px
}

.ant-collapse .ant-collapse-item .ant-collapse-content-box {
  padding-top: 0
}

.ant-collapse-header-text {
  padding: 12px 26px 12px 16px
}

.ant-collapse-header:focus-within {
  outline: 0 !important
}

.ant-collapse-header:has(.ant-collapse-header-text:focus-visible) {
  outline: 2px solid var(--color-accent) !important;
  outline-offset: 10px;
  border-radius: 4px;
  transition: none
}

.ant-collapse-item:has(.ant-collapse-header:focus-visible) {
  outline: 2px solid var(--color-accent) !important;
  border-radius: 4px;
  transition: none
}

.ant-collapse:not(:has(input:focus)):not(:has(textarea:focus)) *:focus-visible, .ant-collapse:not(:has(input:focus)):not(:has(textarea:focus)) .uds__select:focus-visible {
  outline: 2px solid var(--color-accent) !important;
  outline-offset: 2px;
  border-radius: 4px;
  transition: none
}

.ant-collapse:not(:has(input:focus)):not(:has(textarea:focus)) .ant-input:focus-visible {
  outline: none
}

.ant-picker-panel {
  width: 100%
}

.ant-picker-time-panel-column > li .ant-picker-time-panel-cell-inner:hover {
  color: var(--color-black);
  background: var(--color-background)
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--color-accent);
  color: var(--color-white)
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner:hover {
  background: var(--color-accent-hover)
}

.ant-picker-panel-container {
  box-shadow: none;
  border: 1px solid #909090
}

.ant-picker-time-panel-column::after {
  display: none
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 3px
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background: var(--color-black);
  border-radius: 2px
}

.ant-picker-time-panel-column::-webkit-scrollbar-track {
  width: 5px;
  background: var(--color-background)
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid var(--color-accordion-border)
}

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid var(--color-accordion-border)
}

.ant-picker-ranges {
  display: flex;
  justify-content: space-between;
  padding: 12px
}

.ant-picker-ranges .ant-picker-now-btn {
  color: var(--color-black);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700
}

.ant-picker-ranges .ant-picker-ok {
  flex: 1;
  display: flex;
  justify-content: flex-end
}

.ant-picker-ranges .ant-picker-ok button {
  margin-left: 20px;
  min-height: 34px;
  min-width: 50px;
  margin-bottom: 0;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 16px
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-content {
  margin-left: -32px
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  padding: 11px;
  background: #3b3b3b;
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.1;
  font-weight: 700;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, .2509803922)
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow-content {
  display: none
}

.ant-menu-submenu-popup > .ant-menu {
  background: #3b3b3b;
  margin-left: -18px;
  max-height: 200px
}

.ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar {
  width: 6px
}

.ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--color-black);
  transition: all .2s ease;
  border-radius: 4px
}

.ant-menu-submenu-popup > .ant-menu::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  transition: all .2s ease;
  border-radius: 4px
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-item {
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color-white);
  font-size: 16px;
  line-height: 1.1
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-item-active, .ant-menu-submenu-popup > .ant-menu .ant-menu-item-selected {
  background-color: #474747
}

.ant-menu-submenu-popup > .ant-menu .ant-menu-item-selected {
  font-weight: 700
}

svg[fill=none] {
  fill: #262626
}

.uds__range-picker-container .uds__month__switch-container .uds__current-month {
  font-size: 20px;
  text-transform: uppercase
}

.uds__range-picker-container .uds__month__switch-container .uds__arrows .uds__svg-wrapper:last-child svg {
  height: 14px
}

.uds__tabs__container, .ant-tabs, .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane {
  display: flex;
  flex: 1;
  flex-direction: column
}

.ant-tabs-tabpane-hidden {
  display: none
}

.ant-checkbox-wrapper-disabled .uds__checkbox__label {
  color: var(--color-placeholder)
}

.ant-form-item-has-error .rich-text-editor-wrap {
  border-color: var(--color-error);
  border-width: 2px
}

.ant-form-item-has-error .rich-text-editor-wrap:hover {
  border-color: var(--color-error);
  border-width: 2px
}

.ant-input[disabled], .ant-input[readonly] {
  border-color: var(--color-disabled)
}

:root {
  --loader-background-color: #EEEEEE;
  --loader-highlight-color: #DEDEDE
}

*[data-skeleton=true] {
  position: relative
}

*[data-skeleton=true]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
  background-size: 200% 100%;
  animation: loadingSkeleton 2s infinite ease-in-out
}

@keyframes loadingSkeleton {
  0% {
    background-position: 200% 0
  }
  100% {
    background-position: -200% 0
  }
}
