.use-fe-commons-styles {
	@import 'styles';

}

html {
	min-height: 100%;
}

html,
body,
#root {
	display: flex;
	flex: 1;
	overflow-x: hidden;
	position: relative;
}

.App {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

//Overwrites
//TODO remove after DS is updated
.read-only-field > div {
	min-height: auto !important;
}

.tab-full-width > div > div > ul {
	margin: 0;
	padding: 0;
}

.tab-full-width > div > div > ul > li {
	width: 50%;
	flex: 1 1 auto;
	border-bottom-width: 4px !important;
	font-weight: 800;
}

.tab-full-width > div > div > ul > div {
	flex: 0 0 auto !important;
}

.framed-card {
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	background-color: #ffffff;
}

body > iframe {
	display: none;
}

header svg + div,
header svg + div + svg {
	display: none;
}

// Overwrites

.select-doku-definitions-modal,
.select-doku-definitions-modal .ant-modal-content {
	width: 1250px !important;
	max-width: 1250px;

	.ant-modal-body {
		overflow-y: auto;
	}
}

// Table
.omnichannel-titleColumn {
	display: flex;
	align-items: center;
	& > svg {
		margin-left: 4px;
	}
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 8px;
}
// End Table

// Modal
.ant-modal-wrap {
	display: initial;
	overflow: auto !important;
	justify-content: initial;
	align-items: initial;
}
.ant-modal {
	padding-top: 84px;
}
.ant-modal-body {
	max-height: initial;
}
// End Modal

// Button
button.ant-btn.ant-btn-primary.ant-btn-dangerous {
	color: #fff;
	border-color: #ff4d4f;
	background-color: #ff4d4f;
}

button.ant-btn.ant-btn-primary.ant-btn-dangerous:hover,
button.ant-btn.ant-btn-primary.ant-btn-dangerous:active,
button.ant-btn.ant-btn-primary.ant-btn-dangerous:focus {
	color: #fff;
	border-color: #ff7875;
	background: #ff7875;
}
// End Button

// Toastify
.Toastify > [class*="top-right"] {
	pointer-events: none;
	> * {
		pointer-events: all;
	}
}
// End Toastify

// Select
body > [class*="menu-portal"] {
	z-index: 99999;
	[class*="option"] {
		font-size: 18px;
	}
}
// End Select

.table-action-menu {
	min-width: 200px !important;
}

.table-action-menu .ant-dropdown-menu {
	padding: 0;
	box-shadow: none;
	border-radius: 4px;
}

.table-action-menu .ant-dropdown-menu-item {
	height: auto;
	padding: 0
}

span.ant-typography.label {
	font-size: 14px;
	line-height: 1.1;
	font-weight: 700
}

